
import * as React from 'react'
import { Link, animateScroll } from 'react-scroll'

const Header = (props: { profile: UserProfile, userPrefs: UserPreferences }) => {
  const { profile, userPrefs } = props
  const { fullName, jobPositions, projects, educations, skills } = profile

  const scrollTop = () => {
    animateScroll.scrollToTop({ smooth: true, delay: 0, duration: 50 })
  }

  return (
    <nav className="navbar" style={{ top: 0, position: `fixed`, zIndex: 1000, backgroundColor: `black` }}>
      <div className="navbar__inner">
        <div className="navbar__items">
          <div className="navbar__brand hoverLarge" style={{ marginLeft: 8 }}>
            <Link to="top" onClick={scrollTop}>
              <span className="navbar__link" style={{ fontWeight: 700, fontSize: `1.1rem` }}>
                {fullName}
              </span>
            </Link>
          </div>
          <div className="navbar__items navbar__items--right">
            {
              !!userPrefs?.portfolioSectionOrder ?
                userPrefs?.portfolioSectionOrder.map(section => {
                  switch (section) {
                    case 'experience':
                      if (profile.jobPositions && profile.jobPositions.length > 0) {
                        return <Link to="experience">
                          <span className="navbar__item navbar__link">
                            Experience
                                </span>
                        </Link>
                      }
                      break;
                    case 'projects':
                      if (profile.projects && profile.projects.length > 0) {
                        return <Link to="projects">
                          <span className="navbar__item navbar__link">
                            Projects
                                </span>
                        </Link>
                      }
                      break;
                    case 'education':
                      if (profile.educations && profile.educations.length > 0) {
                        return <Link to="education">
                          <span className="navbar__item navbar__link">
                            Education
                                </span>
                        </Link>
                      }
                      break;
                    case 'skills':
                      if (profile.skills && profile.skills.length > 0) {
                        return <Link to="skills">
                          <span className="navbar__item navbar__link">
                            Skills
                                </span>
                        </Link>
                      }
                      break;
                    default:
                      break;
                  }
                })
                :
                <>
                  {jobPositions && jobPositions.length > 0 &&
                    <Link to="experience">
                      <span className="navbar__item navbar__link">
                        Experience
                </span>
                    </Link>
                  }
                  {projects && projects.length > 0 &&
                    <Link to="projects">
                      <span className="navbar__item navbar__link">
                        Projects
                </span>
                    </Link>
                  }
                  {educations && educations.length > 0 &&
                    <Link to="education">
                      <span className="navbar__item navbar__link">
                        Education
                </span>
                    </Link>
                  }
                  {skills && skills.length > 0 &&
                    <Link to="skills">
                      <span className="navbar__item navbar__link">
                        Skills
                                </span>
                    </Link>
                  }
                </>
            }
          </div>
        </div>
      </div>
    </nav >
  )
}

export default Header