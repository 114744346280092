import React from 'react'

import styles from '../Common.module.css'
import { joinStringsWithDot, yearRangeDisplay } from '../../../util'
import defaultInstitution from '../../../assets/defaultInstitution.png'
import { replaceNewlinesWithBr } from '../../../util';
import InnerHTML from 'dangerously-set-html-content'


type Props = {
  edus?: Education[]
}

const Education = (props: Props) => {
  const { edus } = props

  return (
    <div className={styles.root} id="education">
      <div className={`card ${styles.cardBody}`}>
        <div className="card__header margin--auto">
          <h3 style={{ fontSize: 36, marginTop: 8, marginBottom: 0 }}>Education</h3>
        </div>
        <div style={{ textAlign: `center` }}>
          {edus && edus.length ?
            edus.map((edu, i) => {
              const { degreeName, activities, fieldOfStudy, grade, schoolName, schoolLogo, description, url, dateRange } = edu
              const displayedDateRange = yearRangeDisplay(dateRange)
              const schoolLogoFull = schoolLogo ?? defaultInstitution
              return (

                <div key={i} className={`card__body ${styles.innerCardBody}`}>
                  <hr style={{ marginTop: 12 }} />
                  {
                    schoolLogo &&
                    <div className={`avatar avatar--vertical ${styles.avatarMobileLogo} margin--auto`}>
                      {url ?
                        <a href={url} target="_blank" rel="noopener noreferrer" >
                          <img className="hoverLarge margin--auto" src={schoolLogoFull} alt={schoolName} />
                        </a>
                        :
                        <img src={schoolLogoFull} alt={schoolLogo} className="margin--auto" />
                      }</div>
                  }
                  <div className="avatar">
                    {
                      <div className={`avatar ${styles.avatarLogo}`}>
                        {url ?
                          <a href={url} target="_blank" rel="noopener noreferrer" style={{ maxWidth: '80%', maxHeight: '80%', margin: 'auto' }} >
                            <img className="hoverLarge" src={schoolLogo ?? schoolLogoFull} alt={schoolName} />
                          </a>
                          :
                          <img src={schoolLogoFull} alt={schoolName} style={{ maxWidth: '80%', maxHeight: '80%', margin: 'auto' }} />
                        } </div>
                    }
                    <div className={`avatar__intro ${styles.avatarContent}`} style={{ textAlign: `left` }}>
                      <h4 className="avatar__name" style={{ fontSize: 20, marginBottom: 4 }}>{schoolName}</h4>
                      <div className={styles.centerOnMobile}>
                        {
                          joinStringsWithDot(
                            [
                              degreeName,
                              fieldOfStudy,
                              displayedDateRange
                            ]

                          )
                        }

                      </div>
                      {grade && <p>Grade: {grade}</p>}
                      {
                        (description && description.length > 0) &&
                        <div className={styles.jobContent}>
                          <InnerHTML html={replaceNewlinesWithBr(description)} />
                        </div>
                      }
                      {
                        activities && activities.length > 0 &&
                        <div className={styles.jobContent}>
                          {<h4 style={{ marginBottom: 2, marginTop: 8 }}>Activities</h4>}
                          <InnerHTML html={replaceNewlinesWithBr(activities)} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )
            })
            :

            <div className={`card__body ${styles.innerCardBody}`}>
              <hr style={{ marginTop: 12 }} />
              <h3 style={{ margin: `auto` }}>Add education histories and it'll appear here!</h3>
            </div>}
        </div>
      </div>
    </div>
  )
}

export default Education