import React from 'react'
import styles from '../Common.module.css'
import { replaceNewlinesWithBr, dateRangeDisplay } from '../../../util';
import ReactPlayer from "react-player"
import InnerHTML from 'dangerously-set-html-content'


type Prop = {
    projs?: Project[]
}

const groupBy = (n: number) => (arr: any[]) => {
    let newArr = []
    while (arr.length) newArr.push(arr.splice(0, n))
    return newArr
}

const IndivCard = (props: { proj: Project, offset: number }) => {
    const { proj, offset } = props
    const displayedDateRange = dateRangeDisplay(proj.dateRange)
    const renderDescription: boolean = proj.description !== undefined && proj.description.length > 0
    return (
        <div className={`col col--4 ${offset && `col--offset-${offset}`}`} style={{ marginBottom: `1.5rem` }}>

            <div className="card" style={{ backgroundColor: `var(--ifm-color-gray-800)`, height: `100%` }}>
                <div className="card__header">
                    <h3 style={{ marginBottom: 4 }}>{proj.title}</h3>
                    <h4 style={{ margin: 0, fontWeight: 300 }}>{displayedDateRange}</h4>
                </div>
                {
                    // support EITHER img or video, priority video
                    (proj.projectImg || proj.projectVidUrl) &&
                    <div className="card__image">
                        {
                            proj.projectVidUrl ?
                                <h1 className="aspect-ratio-box-16-9" style={{ marginBottom: 0 }}>
                                    <ReactPlayer
                                        className="aspect-ratio-box-inside-16-9"
                                        width="100%"
                                        height="100%"
                                        controls={true}
                                        title={proj.title}
                                        url={proj.projectVidUrl} />
                                </h1>
                                :
                                <img
                                    src={proj.projectImg}
                                    alt={proj.title}
                                    title={proj.title}
                                    style={{ verticalAlign: `middle` }}
                                />
                        }
                    </div>
                }
                {
                    renderDescription &&
                    <div className="card__body" style={{ textAlign: "justify", paddingTop: 12, fontSize: `0.9rem` }}>
                        {(proj.description && proj.description.length > 0) &&
                            <InnerHTML html={replaceNewlinesWithBr(proj.description)} />}

                    </div>
                }
                {
                    proj.url &&
                    <div className="card__footer">
                        <a href={proj.url} target="_blank" rel="noopener noreferrer" >
                            <button className="button button--secondary button--block">See Project</button>
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}

const Projects = (props: Prop) => {
    const { projs } = props


    return (
        <div className={styles.root} id="projects">
            <div className={`card ${styles.cardBody}`}>
                <div className="card__header margin--auto">
                    <h3 style={{ fontSize: 36, marginTop: 8, marginBottom: 0 }}>Projects</h3>
                </div>
                <div className={`card__body ${styles.innerCardBody}`} style={{ width: `95%` }}>
                    <hr style={{ marginTop: 12 }} />
                    <div style={{ margin: 'auto', textAlign: `center` }}>
                        {projs && projs.length
                            ?
                            groupBy(3)(projs).map((proj3: Project[], index) => {
                                const offset = (3 - proj3.length) * 2
                                return (
                                    <div className="row" key={index}>
                                        {
                                            proj3.map((p, index) => <IndivCard key={index} proj={p} offset={index === 0 ? offset : 0} />)
                                        }
                                    </div>
                                )
                            }
                            )
                            :
                            <h3 style={{ margin: `auto` }}>Add some projects and it'll appear here!</h3>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects
