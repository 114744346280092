import React from 'react'

import styles from './Content.module.css'
import Experience from './Experience/Experience'
import Education from './Education/Education'
import Skills from './Skills/Skills'
import Projects from './Projects/Projects'
import { replaceNewlinesWithBr } from '../../util'
import defaultbanner from '../../assets/defaultbanner.png'
import defaultProfile from '../../assets/defaultProfile.png'
import InnerHTML from 'dangerously-set-html-content'
import Awards from './Awards/Awards'

type Prop = {
    profile: UserProfile;
    userPrefs?: UserPreferences
}

const Hero = (props: { profile: UserProfile }) => {
    const { profile } = props

    return (
        <div className={styles.heroRoot}>
            <img
                className={styles.backgroundImg}
                src={profile.backgroundPic ? profile.backgroundPic : defaultbanner}
                alt="Background"
            />
            <div className={styles.heroInnerContent}>
                <img
                    className={[styles.profilePic, `avatar__photo`].join(` `)}
                    src={profile.profilePic ?? defaultProfile}
                    alt="Profile"
                />
                <div className="avatar__intro" style={{ marginLeft: 0 }}>
                    <h4 className="avatar__name">{profile.fullName}</h4>
                    <small>
                        {profile.headline}
                    </small>
                    {/* <small>
                        {joinStringsWithDot([currentCompany ?? ``, currentSchool ?? ``])}
                    </small> */}
                    <small>
                        {profile.location}
                    </small>

                </div>
            </div>
        </div>

    )
}

const QuoteSection = (props: { quote?: string }) => {
    const { quote } = props
    if (quote && quote.length) {
        return (
            <div style={{ marginTop: -8 }}
                className={`${styles.announcement}, ${styles.announcementDark}`}>
                <div className={styles.announcementInner}>
                    <InnerHTML html={replaceNewlinesWithBr(quote)} />
                </div>
            </div>
        )
    }
    else return <span />
}



const Content = (props: Prop) => {
    const { profile, userPrefs } = props

    if (userPrefs?.portfolioSectionOrder) {
        return <div className={styles.root}>
            <Hero profile={profile} />
            <QuoteSection quote={profile.summary ?? ""} />
            {
                userPrefs?.portfolioSectionOrder.map(section => {
                    switch (section) {
                        case 'experience':
                            if (profile.jobPositions && profile.jobPositions.length > 0) {
                                return <Experience poss={profile.jobPositions} />
                            }
                            break;
                        case 'projects':
                            if (profile.projects && profile.projects.length > 0) {
                                return <Projects projs={profile.projects} />
                            }
                            break;
                        case 'education':
                            if (profile.educations && profile.educations.length > 0) {
                                return <Education edus={profile.educations} />
                            }
                            break;
                        case 'skills':
                            if (profile.skills && profile.skills.length > 0) {
                                return <Skills skills={profile.skills} />
                            }
                            break;
                        case 'awards':
                            if (profile.awards && profile.awards.length > 0) {
                                return <Awards awards={profile.awards} />
                            }
                            break;
                        default:
                            break;
                    }
                })
            }
        </div >
    } else {
        return (<div className={styles.root} id="top">
            <Hero profile={profile} />
            <QuoteSection quote={profile.summary ?? ""} />
            {profile.jobPositions && profile.jobPositions.length > 0 && <Experience poss={profile.jobPositions} />}
            {profile.projects && profile.projects.length > 0 && <Projects projs={profile.projects} />}
            {profile.educations && profile.educations.length > 0 && <Education edus={profile.educations} />}
            {profile.awards && profile.awards.length > 0 && <Awards awards={profile.awards} />}
            {profile.skills && profile.skills.length > 0 && <Skills skills={profile.skills} />}
        </div>)
    }

}

export default Content