import React from 'react'
import { joinStringsWithDot, replaceNewlinesWithBr, monthYearDateDisplay } from '../../../util'
import InnerHTML from 'dangerously-set-html-content'

import styles from '../Common.module.css'

type Props = {
    awards?: Award[]
}

const Awards = (props: Props) => {
    const { awards } = props
    return (
        <div className={styles.root} id="awards">
            <div className={`card ${styles.cardBody}`}>
                <div className="card__header margin--auto">
                    <h3 style={{ fontSize: 36, marginTop: 8, marginBottom: 0 }}>Awards</h3>
                </div>
                <div style={{ textAlign: `center` }}>
                    {awards && awards.length ?
                        awards.map((a: Award, i) => {
                            const { name, issuer, date, description } = a
                            const { month, year } = date;
                            const displayedDate = monthYearDateDisplay(month, year);
                            return (

                                <div key={i} className={`card__body ${styles.innerCardBody}`}>
                                    <hr style={{ marginTop: 12 }} />
                                    <div className="avatar">
                                        <div className={`avatar__intro ${styles.avatarContent}`}>
                                            <h4 className="mobile-center-full-left avatar__name" style={{ fontSize: 20, marginBottom: 4 }}>{name}</h4>
                                            <div className={styles.centerOnMobile}>
                                                {
                                                    joinStringsWithDot([
                                                        issuer,
                                                        displayedDate,
                                                    ])
                                                }
                                            </div>
                                            <div className={styles.awardContent}>
                                                {(description && description.length > 0) && <InnerHTML html={replaceNewlinesWithBr(description)} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :

                        <div className={`card__body ${styles.innerCardBody}`}>
                            <hr style={{ marginTop: 12 }} />
                            <h3 style={{ margin: `auto` }}>Add some awards and it'll appear here!</h3>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Awards