import React, { useState, useEffect } from 'react';

import '../node_modules/infima/dist/css/default-dark/default-dark.css';
import './App.css';
import Header from './containers/Header/Header';
import Footer from './containers/Footer/Footer';
import Content from './containers/Content/Content';

const App = () => {
  const [profile, setProfile] = useState<UserProfile | undefined>(undefined)
  const [userPrefs, setUserPrefs] = useState<UserPreferences>()
  const [error, setError] = useState(false)

  useEffect(() => {
    try {
      if (window.location.hostname.includes("portosaurus.com") || window.location.hostname === "localhost" || window.location.hostname === "portosaurus-cf425.web.app") {
        const localAuthState = window.localStorage.getItem(`profileState`)
        if (localAuthState) {
          // console.log(`got from localStorage`)
          const got: ProfileState = JSON.parse(localAuthState)
          const profile = got.profile
          if (!profile)
            throw new Error(`No profile Info!`)
          setProfile(profile)
          setUserPrefs(got.userPreferences)
        }
        else {
          setError(true)
        }
      }
      else {
        // console.log(`fetching from json`)
        /// fetchit
        fetch(`user-profile.json`)
          .then(response => response.json())
          .then(data => { setProfile(data) })
          .catch((err) => {
            console.error(`Unable to get user profile JSON!`, err)
            setError(true)
          })

        fetch(`user-preferences.json`)
          .then(response => response.json())
          .then(data => { setUserPrefs(data) })
          .catch((err) => {
            console.error(`Unable to get user prefs JSON!`, err)
            setError(true)
          })
      }
    }
    catch (err) {
      setError(true)
    }
  }, [])

  if (error) {
    return (
      <h3 style={{ textAlign: `center`, marginTop: `45vh`, fontSize: 50 }}>Oops... something went wrong!</h3>
    )
  }

  return (
    <div className="App">
      {profile && userPrefs ?
        <div>
          <Header profile={profile} userPrefs={userPrefs} />
          <Content profile={profile} userPrefs={userPrefs} />
          <Footer contacts={profile.contacts} />
        </div>
        : <h3 style={{ textAlign: `center`, marginTop: `45vh`, fontSize: 50 }}>Loading...</h3>
      }
    </div>
  );
}

export default App;
