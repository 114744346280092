import * as React from 'react'


type Prop = { contacts?: Contact[] }

const Footer = (props: Prop) => {
    const { contacts } = props
    return (
        <footer className="footer" style={{ color: 'white' }}>
            <div className="container container--fluid text--center">
                {
                    (contacts && contacts.length > 0) &&
                    <div className="footer__links">
                        {contacts && contacts
                            .map(({ name, url }, i) => {
                                return (
                                    <a key={i} className="footer__link-item" target="_blank" rel="noopener noreferrer" href={url}>
                                        {name}
                                    </a>
                                )
                            }
                            )
                            .reduce((accu: JSX.Element[] | null, elem, i) => {
                                return accu === null ? [elem] : [...accu, <span key={-i} className="footer__link-separator">·</span>, elem]
                            }, null)}
                    </div>
                }
                <div>Made with <span role="img" aria-label="love">❤️</span> using <a href="https://www.portosaurus.com" target="_blank" rel="noopener noreferrer">Portosaurus</a></div>

            </div>
        </footer>
    )
}

export default Footer
